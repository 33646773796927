<template>
  <div>
    <FilterOverview title="Flugstammdaten" :showFilterInitially="false" @reload="onReload">
      <template #toolbar-left>
        <div
          class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
          style="gap: 12px"
        >
          <RegionFilterButtonGroup
            :regions="['EU', 'FERN']"
            :disabled="regionFilterDisabled"
            v-model="selectedRegions"
          ></RegionFilterButtonGroup>

          <b-button-group size="sm" class="d-flex" style="gap: 2px">
            <b-button
              v-for="priority in priorities"
              :key="priority.text"
              :id="priority.text"
              @click="onPriorityClick"
              :variant="
                prioritiesSelected.includes(priority.text)
                  ? 'primary'
                  : prioritiesSelected.length === 0 && priority.text === 'Alle'
                  ? 'primary'
                  : ''
              "
            >
              {{ priority.text }}
            </b-button>
          </b-button-group>
          <b-form-checkbox v-model="vergangeneTermineAusblenden" @change="gridApi.onFilterChanged()" switch>
            Vergangene Termine ausblenden
          </b-form-checkbox>
          <b-button size="sm ml-2" variant="danger" v-if="isFilterSupplied" @click="resetAllFilters">
            Alle Filter zurücksetzen
          </b-button>
        </div>
      </template>
      <template #table="{ tableHeight }">
        <AgGridVue
          :style="{ height: tableHeight + 80 + 'px' }"
          class="ag-theme-alpine m-0 p-0"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowModelType="'serverSide'"
          :getRowHeight="getRowHeight"
          :statusBar="statusBar"
          suppressRowTransform
          :rowMultiSelectWithClick="'true'"
          :tooltipShowDelay="0"
          @grid-ready="onGridReady"
          @rowDoubleClicked="onRowDoubleClicked"
          suppressCellFocus
        ></AgGridVue>
      </template>
    </FilterOverview>
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import FilterOverview from '@/components/common/filter-overview.vue';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import { mapState, mapGetters } from 'vuex';
import { add } from 'date-fns';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';
import AgDateRangePicker from '@/components/flugverfuegbarkeit/ag-date-range-picker.vue';
import * as odataService from '@/core/common/services/odata.service';
import { fernRegions, euRegions } from '@/core/common/helpers/utils.js';
import { format } from 'date-fns';
import CellRendererAufgaben from '@/components/produkte/reisetermine/cell-renderer-aufgaben.vue';
import FilterRendererInlandsflugEinkauf from '@/components/common/filter-renderer-inlandsflug-einkauf.vue';
import { garantiertFilterRenderer } from '@/core/flugverfuegbarkeit/yield-table-config.js';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import { flugstammdatenAufgabenKategorien } from '@/core/common/helpers/utils.js';
import RegionFilterButtonGroup from '@/components/common/region-filter-button-group.vue';

export default {
  components: {
    FilterOverview,
    AgGridVue,
    AgDateRangePicker,
    CellRendererAufgaben,
    FilterRendererInlandsflugEinkauf,
    garantiertFilterRenderer,
    agColumnHeader: CustomHeader,
    StatusBarComponent,
    RegionFilterButtonGroup,
  },

  data() {
    return {
      groupedThWerte: null,
      reiseterminWithChangedThWert: [],
      isFilterSupplied: false,
      statusBar: null,
      odataFilterReiseterminkuerzel: null,
      vergangeneTermineAusblenden: true,
      expand: {
        reise: {},
        metadata: {
          expand: {
            inlandsfluege: {},
            ziellandAbflughaefen: {},
            ziellandZielflughaefen: {},
          },
        },
        pax: {},
        gueltigerSaisonpreis: {},
        flugeinkauf: {},
        aufgaben: {},
      },
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        flex: 1,
        filter: true,
        sortable: false,
        resizable: false,
        floatingFilter: true,
        suppressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
      },
      regionFilterDisabled: true,
      priorities: [
        { value: null, text: 'Alle' },
        { value: 0, text: 'A' },
        { value: 1, text: 'B' },
        { value: 2, text: 'C' },
        { value: 3, text: 'D' },
      ],
      selectedRegions: [],
      prioritiesSelected: [],
    };
  },
  watch: {
    // schaut auf das array "selectedRegions" und dessen veränderung
    selectedRegions(newSelectedRegions) {
      // und geben das newSelectedRegions aus dem emit in die methode
      this.changeRegionFilterInstance(newSelectedRegions);
    },
  },
  created() {
    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {
            field: 'istZusatztermin',
            cellRenderer: params => {
              if (params.value) {
                return 'Z';
              }
              return '';
            },
            filterParams: {
              values: [true, false],
              valueFormatter: params =>
                params.value === true
                  ? 'Zusatztermin'
                  : params.value === false
                  ? 'Kein Zusatztermin'
                  : params.value,
            },
            headerName: '',
            pinned: 'left',
            width: 50,
            minWidth: 50,
          },
          {
            field: 'metadata/inlandsfluegeEinkauf',
            headerName: '',
            minWidth: 55,
            width: 55,
            filter: 'agSetColumnFilter',
            pinned: 'left',
            filterParams: {
              values: ['NurSkr', 'NurDmc', 'Gemischt', 'KeinInlandsflug'],
              cellRenderer: 'FilterRendererInlandsflugEinkauf',
              debounceMs: 1000,
              comparator: (a, b) => {
                const ranking = {
                  KeinInlandsflug: 0,
                  NurSkr: 1,
                  NurDmc: 2,
                  Gemischt: 3,
                };

                if (a === 'null') return 1;
                else if (ranking[a] === ranking[b]) return 0;
                return ranking[a] > ranking[b] ? 1 : -1;
              },
            },
            cellRenderer: params => {
              const { inlandsfluege } = params.data.metadata;
              const nurSkrInlandsfluege = inlandsfluege.every(inlandsflug => inlandsflug.einkauf === 'Skr');
              const nurDmcInlandsfluege = inlandsfluege.every(inlandsflug => inlandsflug.einkauf === 'Dmc');
              const skrOderDmcInlandsfluege = inlandsfluege.every(
                inlandsflug => inlandsflug.einkauf === 'Dmc' || inlandsflug.einkauf === 'Skr'
              );
              if (inlandsfluege.length === 0) return '';
              else if (nurSkrInlandsfluege) return '<i class="fas fa-plane text-danger"></i>';
              else if (nurDmcInlandsfluege) return '<i class="fas fa-plane" style="color: #000"></i>';
              else if (skrOderDmcInlandsfluege) return '<i class="fas fa-plane" style="color: #A6A6A8"></i>';
            },
            floatingFilter: true,
            resizable: false,
          },
          {
            field: 'reise.reisekuerzel',
            headerName: 'Reise',
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
              refreshValuesOnOpen: true,
              values: params => params.success(this.reisenOptions),
              debounceMs: 1000,
            },
            floatingFilter: true,
            width: 130,
            minWidth: 130,
            pinned: 'left',
          },
          {
            field: 'abreisedatum',
            headerName: 'Abreise',
            filter: 'AgDateRangePicker',
            floatingFilterComponent: 'AgDateRangePicker',
            floatingFilterComponentParams: {
              isChild: true,
            },
            cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
            width: 150,
            minWidth: 150,
            pinned: 'left',
          },
          {
            field: 'hotelendUndRueckflugdatum',
            headerName: 'Rückflugdatum',
            filter: 'AgDateRangePicker',
            floatingFilterComponent: 'AgDateRangePicker',
            floatingFilterComponentParams: {
              isChild: true,
            },
            cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
            width: 150,
            minWidth: 150,
            pinned: 'left',
          },
          {
            field: 'ziellandZielflughaefen',
            headerName: 'ZL Zielflughäfen',
            cellRenderer: params => {
              const { ziellandZielflughaefen } = params.data.metadata;
              return ziellandZielflughaefen?.map(fh => fh.flughafenIataCode).join('/') ?? '';
            },
            filterParams: { values: this.alleFlughaefenIataCode },
            minWidth: 125,
            width: 125,
          },
          {
            field: 'ziellandAbflughaefen',
            headerName: 'ZL Abflughäfen',
            cellRenderer: params => {
              const { ziellandAbflughaefen } = params.data.metadata;
              return ziellandAbflughaefen?.map(fh => fh.flughafenIataCode).join('/') ?? '';
            },
            filterParams: { values: this.alleFlughaefenIataCode },
            minWidth: 125,
            width: 125,
          },
          {
            field: 'startdatum',
            headerName: 'Startdatum',
            filter: 'AgDateRangePicker',
            floatingFilterComponent: 'AgDateRangePicker',
            floatingFilterComponentParams: {
              isChild: true,
            },
            cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
            width: 150,
            minWidth: 150,
          },
          {
            field: 'metadata.overnightOutbound',
            headerName: 'OB',
            cellRenderer: params => (params.value === null ? '-' : params.value),
            minWidth: 70,
            width: 70,
            filterParams: {
              values: [0, 1, 2],
            },
          },
          {
            field: 'metadata.overnightInbound',
            headerName: 'IB',
            cellRenderer: params => (params.value === null ? '-' : params.value),
            minWidth: 70,
            width: 70,
            filterParams: {
              values: [0, 1, 2],
            },
          },
        ],
      },
      {
        headerName: 'Hinflug',
        headerClass: 'hinflug-header',
        children: [
          {
            field: 'metadata.fruehesteAnkunft',
            headerName: 'Früh. Ankunft',
            filter: false,
            cellRenderer: params => (params.value ? params.value.substring(0, 5) + ' Uhr' : '-'),
            minWidth: 120,
            width: 120,
          },
          {
            field: 'metadata.spaetesteAnkunft',
            headerName: 'Spät. Ankunft',
            filter: false,
            cellRenderer: params =>
              params.value
                ? `${params.value.substring(0, 5)} Uhr ${
                    params.data.metadata.restriktionenHinRollover ? '+1' : ''
                  }`
                : '-',
            minWidth: 120,
            width: 120,
          },
        ],
      },
      {
        headerName: 'Rückflug',
        headerClass: 'rueckflug-header',
        children: [
          {
            field: 'metadata.fruehesterAbflug',
            headerName: 'Früh. Abflug',
            filter: false,
            cellRenderer: params => (params.value ? params.value.substring(0, 5) + ' Uhr' : '-'),
            minWidth: 120,
            width: 120,
          },
          {
            field: 'metadata.spaetesterAbflug',
            headerName: 'Spät. Abflug',
            filter: false,
            cellRenderer: params =>
              params.value
                ? `${params.value.substring(0, 5)} Uhr ${
                    params.data.metadata.restriktionenRueckRollover ? '+1' : ''
                  }`
                : '-',
            minWidth: 120,
            width: 120,
          },
        ],
      },
      {
        headerName: '',
        children: [
          {
            field: 'metadata.hotelstartVorLandung',
            headerName: ' Hotelstart v. Landung',
            sortable: true,
            cellRenderer: params => {
              const { value } = params;
              if (value) {
                return "<i class='fas fa-check text-success fa-lg'/>";
              } else {
                return "<i class='ml-1 fas fa-times text-danger fa-lg'/>";
              }
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              values: [true, false],
              cellRenderer: garantiertFilterRenderer,
              debounceMs: 1000,
            },
            minWidth: 190,
            width: 190,
          },
          {
            field: 'metadata.rueckflugNachHotelende',
            headerName: ' Rückflug n. Hotelende',
            sortable: true,
            cellRenderer: params => {
              const { value } = params;
              if (value) {
                return "<i class='fas fa-check text-success'/>";
              } else {
                return "<i class='ml-1 fas fa-times text-danger'/>";
              }
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              values: [true, false],
              cellRenderer: garantiertFilterRenderer,
              debounceMs: 1000,
            },
            minWidth: 190,
            width: 10,
          },
          {
            field: 'metadata.transferszenarioHin',
            headerName: 'TS Hin',
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            filterParams: {
              values: [
                'T1_1inkl',
                'T2_1inkl_exkl_mögl',
                'T3_unbegr_inkl',
                'T4_unbegr_exkl',
                'T5_fest_inkl',
                'T5_2',
                'T5_3',
                'T6',
                'TX_ohne',
                'TS',
              ],
              valueFormatter: params => {
                if (params.value === 'T5_2' || params.value === 'T5_3') {
                  return params.value;
                }
                return params.value?.substring(0, 2);
              },
              debounceMs: 1000,
              comparator: (a, b) => (a === '0€' ? 1 : a < b),
            },
            cellRenderer: params => {
              if (params.value === 'T5_2' || params.value === 'T5_3') {
                return params.value;
              }
              return params.value?.substring(0, 2);
            },
            width: 70,
            minWidth: 70,
          },
          {
            field: 'metadata.transferszenarioRueck',
            headerName: 'TS Rück',
            filter: 'agSetColumnFilter',
            filterParams: {
              values: [
                'T1_1inkl',
                'T2_1inkl_exkl_mögl',
                'T3_unbegr_inkl',
                'T4_unbegr_exkl',
                'T5_fest_inkl',
                'T5_2',
                'T5_3',
                'T6',
                'TX_ohne',
                'TS',
              ],
              valueFormatter: params => {
                if (params.value === 'T5_2' || params.value === 'T5_3') {
                  return params.value;
                }
                return params.value?.substring(0, 2);
              },
              debounceMs: 1000,
              comparator: (a, b) => (a === '0€' ? 1 : a < b),
            },
            floatingFilter: true,
            cellRenderer: params => {
              if (params.value === 'T5_2' || params.value === 'T5_3') {
                return params.value;
              }
              return params.value?.substring(0, 2);
            },
            width: 70,
            minWidth: 70,
          },
          {
            field: 'metadata.vkTransferkostenHin',
            headerName: 'VK Transferkosten Hin',
            filter: false,
            cellRenderer: params => {
              if (params.value) {
                const roundedValue = Math.round(params.value / 10) * 10;
                return `${roundedValue}€`;
              } else {
                return '-';
              }
            },
            minWidth: 170,
            width: 170,
          },
          {
            field: 'metadata.vkTransferkostenRueck',
            headerName: 'VK Transferkosten Rück',
            filter: false,
            cellRenderer: params => {
              if (params.value) {
                const roundedValue = Math.round(params.value / 10) * 10;
                return `${roundedValue}€`;
              } else {
                return '-';
              }
            },
            minWidth: 190,
            width: 190,
          },
          {
            field: 'metadata.vkVornachtDzProPerson',
            headerName: 'VK kalk. Vornacht DZ p.P.',
            filter: false,
            cellRenderer: params => {
              if (params.value) {
                const roundedValue = Math.round(params.value / 10) * 10;
                return `${roundedValue}€`;
              } else {
                return '-';
              }
            },
            minWidth: 190,
            width: 190,
          },
          {
            field: 'reise.region',
            hide: true,
            filter: 'agSetColumnFilter',
            filterParams: {
              values: ['EU', 'Fern'],
              debounceMs: 1000,
            },
          },
          {
            field: 'thLangstrecke',
            headerName: 'TH Langstrecke',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['apply'] },
            cellStyle: params => {
              const { reiseterminkuerzel } = params.node.data;
              if (this.reiseterminWithChangedThWert.includes(reiseterminkuerzel)) {
                return { backgroundColor: '#FFA80050' };
              }
            },
            width: 130,
            minWidth: 130,
          },
          {
            field: 'flinkokZx',
            headerName: 'Flinkok ZX',
            filter: false,
            width: 110,
            minWidth: 110,
          },
          {
            field: 'flinkokZth',
            headerName: 'Flinkok ZTH',
            filter: false,
            width: 110,
            minWidth: 110,
          },
          {
            field: 'flinkokZtr',
            headerName: 'Flinkok ZTR',
            filter: false,
            width: 110,
            minWidth: 110,
          },
          {
            field: 'flinkokZtv',
            headerName: 'Flinkok ZTV',
            filter: false,
            width: 110,
            minWidth: 110,
          },
          {
            field: 'aufgaben',
            headerName: 'Aufgaben',
            cellRenderer: 'CellRendererAufgaben',
            floatingFilter: false,
            cellRendererParams: params => ({
              data: params.data,
              filter: 'flugstammdaten',
              ansicht: 'flugstammdaten-overview',
            }),
            minWidth: 550,
            width: 550,
          },
        ],
      },
    ];
  },
  computed: {
    ...mapGetters(['currentUserId', 'getReisekuerzelOptions']),
    ...mapState({
      alleFlughaefen: state => state.flughaefen.flughaefen,
    }),
    alleFlughaefenIataCode() {
      return this.alleFlughaefen?.map(flughafen => flughafen.iataCode);
    },
    reisenOptions() {
      return this.getReisekuerzelOptions.map(r => r.reisekuerzel);
    },
    identicalInlandsfluegeSelectedReisetermine() {
      if (this.allSelectedRows.length <= 1) {
        return null;
      }

      const allInlandsfluege = this.allSelectedRows.reduce((acc, curr) => {
        const inlandsfluege = [];
        curr.data.metadata.inlandsfluege.forEach(inlandsflug => {
          inlandsfluege.push({ ...inlandsflug, reiseterminId: curr.data.id });
        });
        acc.push(inlandsfluege);
        return acc;
      }, []);

      if (allInlandsfluege.some(inlandsfluege => inlandsfluege === null || inlandsfluege.length === 0)) {
        return null;
      }
      const identicalInlandsfluege = allInlandsfluege.flat().reduce((acc, curr) => {
        const { tag, abflughafenIataCode, id, reiseterminId, flugnummer, zielflughafenIataCode } = curr;
        const existing = acc.find(
          item => item.abflughafenIataCode === abflughafenIataCode && item.tag === tag
        );
        if (existing) {
          existing.reiseterminId_inlandsflugId.push(`${reiseterminId}:${id}`);
          if (existing.flugnummer !== flugnummer) existing.flugnummer = null;
        } else {
          {
            acc.push({
              abflughafenIataCode,
              tag,
              reiseterminId_inlandsflugId: [`${reiseterminId}:${id}`],
              flugnummer,
              zielflughafenIataCode,
            });
          }
        }
        return acc;
      }, []);

      // check if the identical inlandsfluege are shared over each row
      const inlandsfluegeSharedOverEachRow = identicalInlandsfluege.filter(
        item => item.reiseterminId_inlandsflugId.length === this.allSelectedRows.length
      );
      return inlandsfluegeSharedOverEachRow;
    },
  },
  mounted() {},
  methods: {
    groupThWerte(data) {
      // acc ist das Objekt, dass die daten am ende enthält
      this.groupedThWerte = data.reduce((acc, curr) => {
        // holen der reisekuerzel aus dem curr-Objekt
        const { reisekuerzel } = curr.reise;
        // es wird überprüft, ob das acc Objekt schon das reiseküzel als Eintrag hat
        if (acc.hasOwnProperty(reisekuerzel)) {
          // Dann werden startdatum und thWerte listen erstellt und dementsprechend befüllt
          acc[reisekuerzel] = {
            startdatums: [...acc[reisekuerzel]['startdatums'], curr.startdatum],
            thWerte: [...acc[reisekuerzel]['thWerte'], curr.thLangstrecke],
          };
        } else {
          // sonst wird der Eintrag mit dem reisekuerzel erstellt und befüllt
          acc[reisekuerzel] = { startdatums: [curr.startdatum], thWerte: [curr.thLangstrecke] };
        }
        return acc;
        // initialwert des acc
      }, {});
      // groupedThWerte wird in array von [key, value]-Paaren umgewandelt -> wird dann durchlaufen
      Object.entries(this.groupedThWerte).forEach(([reisekuerzel, values]) => {
        // wird für jeden thWert überprüft,
        values.thWerte.forEach((thWert, index) => {
          if (index > 0) {
            // ob der vorheriger sich zu dem aktuellen unterscheidet
            const lastThWert = values.thWerte[index - 1];
            if (lastThWert != thWert) {
              // dann das reisekürzel mit dem index des Startdatums in reiseterminWithChangedThWert gespeichert
              const startdatum = values.startdatums[index];
              this.reiseterminWithChangedThWert.push(reisekuerzel + '_' + startdatum.substring(0, 10));
            }
          }
        });
      });
    },
    resetAllFilters() {
      this.gridApi.setFilterModel(null);
      let filterDate1 = this.gridApi.getFilterInstance('abreisedatum');
      let filterDate2 = this.gridApi.getFilterInstance('startdatum');
      let filterDate3 = this.gridApi.getFilterInstance('hotelendUndRueckflugdatum');
      this.selectedRegions = [];
      this.prioritiesSelected = [];
      this.vergangeneTermineAusblenden = true;
      this.odataFilterReiseterminkuerzel = null;
      const query = { ...this.$route.query };
      if ('reisetermine' in query) {
        delete query['reisetermine'];
        this.$router.push({ query });
      }
      filterDate1.onDateRangeFilterChanged(null);
      filterDate2.onDateRangeFilterChanged(null);
      filterDate3.onDateRangeFilterChanged(null);
    },
    onReload() {
      this.gridApi.onFilterChanged();
    },
    getRowHeight(params) {
      const amountStammdatenAufgaben = params.data?.aufgaben?.filter(aufgabe =>
        flugstammdatenAufgabenKategorien.includes(aufgabe.kategorie)
      ).length;
      return params.data ? Math.max(1, amountStammdatenAufgaben) * 45 : 45;
    },
    getAllSelectedRows() {
      const rowsSelected = [];
      this.gridApi.forEachNode((node, index) => {
        if (node.selected) {
          rowsSelected.push(node);
        }
      });
      return rowsSelected;
    },
    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    onPriorityClick($event) {
      const { id: priorityText } = $event.target;
      if (priorityText === 'Alle') {
        this.prioritiesSelected = [];
      } else if (!this.prioritiesSelected.includes(priorityText)) {
        this.prioritiesSelected.push(priorityText);
      } else {
        this.prioritiesSelected = this.prioritiesSelected.filter(priority => priority !== priorityText);
      }
      // Wenn alle angeklickt sind, resette
      if (this.prioritiesSelected.length === this.priorities.length - 1) {
        this.prioritiesSelected = [];
      }

      this.gridApi.onFilterChanged();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      if (this.$route.query.reisetermine) {
        this.odataFilterReiseterminkuerzel = `reiseterminkuerzel in (${this.$route.query.reisetermine
          .split(',')
          .map(rt => `'${rt}'`)
          .join(',')})`;
        this.gridApi?.onFilterChanged();
      }

      const updateData = () => {
        const server = this.server();
        const datasource = this.createDatasource(server);
        params.api.setServerSideDatasource(datasource);
      };
      updateData();
    },
    createDatasource(server) {
      return {
        getRows: async params => {
          console.log('[Datasource] - rows requested by grid: ', params.request);
          const response = await server.getData(params.request);
          if (response.success) {
            params.success({
              rowData: response.rows,
            });
            this.updateStatusBar(this.gridApi?.getDisplayedRowCount());
          } else {
            params.fail();
          }
        },
      };
    },
    server() {
      return {
        getData: async request => {
          this.checkIfFilterSupplied();
          const response = await odataService.getReisetermin({
            filter: this.getOdataFilterFromAgGridRequest(request),
            top: request.endRow - request.startRow,
            skip: request.startRow,
            count: true,
            expand: this.expand,
            ...(request.sortModel.length > 0
              ? {
                  orderBy: request.sortModel.map(s => s.colId.replaceAll('.', '/') + ' ' + s.sort),
                }
              : { orderBy: 'reise/prioritaet asc, reise/reisekuerzel asc, abreisedatum asc' }),
          });
          this.count = response.count;
          this.regionFilterDisabled = false;
          this.groupThWerte(response.data);
          return {
            success: true,
            rows: response.data,
          };
        },
      };
    },
    getOdataFilterFromAgGridRequest(request) {
      this.checkIfFilterSupplied();

      const filterKeys = Object.keys(request.filterModel);
      let filters = [];

      const allFilterKeys = filterKeys.map(key => {
        const formattedKey = key.replaceAll('.', '/');
        if (request.filterModel[key].filterType === 'text') {
          if (
            [
              'thLangstrecke',
              'metadata/vkTransferkostenHin',
              'metadata/vkTransferkostenRueck',
              'metadata/vkVornachtDzProPerson',
            ].includes(formattedKey)
          ) {
            if (request.filterModel[key].filter.includes('-')) {
              const min = parseFloat(request.filterModel[key].filter.split('-')[0]);
              const max = parseFloat(request.filterModel[key].filter.split('-')[1]);
              return `${formattedKey} le ${max} and ${formattedKey} ge ${min}`;
            } else if (request.filterModel[key].filter[0] === '>') {
              const min = parseFloat(request.filterModel[key].filter.split('>')[1]);
              return `${formattedKey} gt ${min}`;
            } else if (request.filterModel[key].filter[0] === '<') {
              const max = parseFloat(request.filterModel[key].filter.split('<')[1]);
              return `${formattedKey} lt ${max}`;
            } else return { [formattedKey]: parseFloat(request.filterModel[key].filter) };
          }
          return { [formattedKey]: { startswith: request.filterModel[key].filter } };
        } else if (request.filterModel[key].filterType === 'set') {
          if (key === 'reise.region') {
            const regionValues = request.filterModel[key].values
              .map(value => (value === 'Fern' ? fernRegions : euRegions))
              .flat();
            return { [formattedKey]: { in: regionValues } };
          }
          if (key === 'ziellandZielflughaefen' || key === 'ziellandAbflughaefen') {
            const { values } = request.filterModel[key];
            const formattedValues = values.map(flughafen => `'${flughafen}'`).join(',');
            const filterString = `metadata/${key}/any(flughafen: flughafen/flughafenIataCode in (${formattedValues}))`;
            return filterString;
          }
          return { [formattedKey]: { in: request.filterModel[key].values } };
        } else if (request.filterModel[key].filterType === 'date') {
          const dateFrom = new Date(request.filterModel[key].dateFrom.substring(0, 10));
          const dateTo = add(new Date(request.filterModel[key].dateTo), { hours: 23, minutes: 59 });

          return {
            [formattedKey]: {
              ge: dateFrom,
              le: dateTo,
            },
          };
        }
      });
      filters = [
        ...filters,
        //die benötigten filter, damit nur die bestimmten Termine geladen werden
        {
          isDeleted: { ne: true },
        },
        {
          or: [{ state: { eq: 'Anlagebereit' } }, { state: { eq: 'Aufgelegt' } }],
        },
        ...allFilterKeys,
      ];

      if (this.vergangeneTermineAusblenden) {
        filters = [
          ...filters,
          {
            abreisedatum: { ge: new Date() },
          },
        ];
      }

      //   hier dann abfrage, ob aufgaben erledigt sind
      if (this.prioritiesSelected.length > 0) {
        filters = [
          ...filters,
          {
            ['reise/prioritaet']: {
              in: this.prioritiesSelected.map(
                prioritaet => this.priorities.find(p => p.text === prioritaet)?.value
              ),
            },
          },
        ];
      }

      if (this.odataFilterReiseterminkuerzel) {
        filters = [...filters, this.odataFilterReiseterminkuerzel];
      }

      return filters;
    },
    onRowDoubleClicked($event) {
      const { data, rowIndex } = $event;
      this.selectedRowID = rowIndex;
      const routeData = this.$router.resolve({ path: '/reisetermine/' + data.id });
      window.open(routeData.href, '_blank');
    },
    checkIfFilterSupplied() {
      this.isFilterSupplied =
        this.gridApi &&
        (Object.keys(this.gridApi?.getFilterModel()).length > 0 ||
          this.regionSelected != null ||
          !this.vergangeneTermineAusblenden ||
          this.prioritiesSelected.length > 0 ||
          this.odataFilterReiseterminkuerzel !== null);
    },
    changeRegionFilterInstance(selectedRegions) {
      if (!this.gridApi) return;

      const regionsFilter = this.gridApi.getFilterInstance('reise.region');
      if (!regionsFilter) return;

      if (selectedRegions.length > 0) {
        regionsFilter.setModel({ values: selectedRegions });
      } else {
        regionsFilter.setModel({});
      }
      this.gridApi.onFilterChanged();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';
:deep(.ag-theme-alpine) {
  --ag-font-family: 'Poppins';
  --ag-font-size: 13px;
  --ag-tab-min-width: 300px;
}
:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 500;
  font-size: 14px;
}
:deep(.ag-theme-alpine .ag-header-group-cell) {
  font-weight: 500;
  font-size: 14px;
}
:deep(.ag-cell),
:deep(.ag-header-cell) {
  padding: 0px 0.5rem;
}
:deep(.hinflug-header) {
  background-color: #aab7b876;
}
:deep(.rueckflug-header) {
  background-color: #515a5a76;
}
:deep(.dots-dropdown) {
  font-weight: 600;
  font-size: 14px;
}
</style>
