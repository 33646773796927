const moment = require('moment');

export const dateFormatterStandard = params => {
  const abfragedatum = params.value;
  if (!abfragedatum) return '';
  return moment(abfragedatum, moment.ISO_8601).format('DD.MM.YYYY');
};
export const dateFormatterWithHours = params => {
  const abfragedatum = params.value;
  if (!abfragedatum) return '';
  return moment(abfragedatum, moment.ISO_8601).format('DD.MM.YYYY HH:mm');
};

export const fehlertypen = [
  'UNBEKANNT',
  'FLUG_MIT_HOTEL_NICHT_KOMBINIERBAR',
  'BIS_DATUM_FALSCH_ODER_FEHLT',
  'RUNDREISE_AUSGEBUCHT',
  'MINIMALE_AUFENTHALTSDAUER_UNTERSCHRITTEN',
  'DAS_ZIMMERKONTINGENT_IST_AUSGEBUCHT',
  'LEISTUNG_HOTEL_FUER_DIESEN_ZEITRAUM_NICHT_BUCHBAR',
  'LEISTUNG_FLUG_IST_AUSGEBUCHT',
  'LEISTUNG_FLUG_IST_NICHT_IM_VERANSTALTERANGEBOT',
  'ANREISETAG_NICHT_MOEGLICH',
  'ZIMMERBELEGUNG_IST_FALSCH',
  'NEBENLEISTUNG_IST_NICHT_BUCHBAR',
  'LEISTUNG_FLUG_DER_HINFLUG_IST_AUSGEBUCHT',
  'LEISTUNG_3_LETTER_CODE_IST_UNGUELTIG',
  'BITTE_KEINE_LUECKEN_DATUMSUEBERSCHNEIDUNGEN_BEI_DEN_HOTEL_BUCHUNGEN',
  'MINDESTAUFENTHALT_IST_UNTERSCHRITTEN',
  'LEISTUNG_FLUG_DER_RUECKFLUG_IST_AUSGEBUCHT',
  'KEIN_PREIS_VORHANDEN',
  'FLUG_KANN_ZUR_ZEIT_NICHT_GEBUCHT_WERDEN',
  'DAS_RESERVIERUNGSSYSTEM_IST_MOMENTAN_NICHT_VERFUEGBAR',
];

export const garantiertFilterRenderer = params => {
  if (params.value === '(Select All)') {
    return params.value;
  } else if (params.value) {
    return "<i class='fas fa-check text-success fa-lg'/>";
  } else if (!params.value) {
    return "<i class='ml-1 fas fa-times text-danger fa-lg'/>";
  }
};

export const bearbeitungsFarben = [
  {
    label: 'Akzeptiert',
    value: 'Akzeptiert',
  },
  {
    label: 'Verfügbarkeit geschaffen',
    value: 'VerfuegbarkeitGeschaffen',
  },
  {
    label: 'Verfügbarkeit nicht geschaffen',
    value: 'VerfuegbarkeitNichtGeschaffen',
  },
  {
    label: 'Nicht bearbeitet',
    value: 'null',
  },
];

export const zimmertypen = [
  'AA',
  'AAM',
  'AP',
  'APM',
  'ASM',
  'BAG',
  'BAM',
  'BAP',
  'BAS',
  'BBA',
  'BBP',
  'BFA',
  'BFP',
  'BPA',
  'BU',
  'BUA',
  'BUB',
  'BUG',
  'BUM',
  'BUP',
  'BUS',
  'BZA',
  'DA',
  'DAB',
  'DAC',
  'DAE',
  'DAF',
  'DAG',
  'DAH',
  'DAK',
  'DAL',
  'DAM',
  'DAP',
  'DAR',
  'DAS',
  'DB',
  'DBF',
  'DBS',
  'DBV',
  'DBW',
  'DCG',
  'DDA',
  'DDG',
  'DDH',
  'DDM',
  'DEX',
  'DF',
  'DG',
  'DGA',
  'DGH',
  'DKL',
  'DLA',
  'DLH',
  'DM',
  'DMS',
  'DP',
  'DS',
  'DSM',
  'DSV',
  'DT',
  'DTZ',
  'DVB',
  'DVS',
  'DZ',
  'DZB',
  'DZC',
  'DZD',
  'DZE',
  'DZF',
  'DZG',
  'DZH',
  'DZK',
  'DZM',
  'DZP',
  'DZR',
  'DZS',
  'DZT',
  'DZU',
  'DZZ',
  'EB',
  'EBF',
  'EBW',
  'ECG',
  'EDG',
  'EDM',
  'EG',
  'EGA',
  'EP',
  'ES',
  'ESM',
  'ESV',
  'ET',
  'EXM',
  'EZ',
  'EZA',
  'EZB',
  'EZD',
  'EZE',
  'EZF',
  'EZG',
  'EZH',
  'EZK',
  'EZM',
  'EZP',
  'EZR',
  'EZS',
  'EZT',
  'EZU',
  'EZX',
  'HAM',
  'HAP',
  'HB',
  'HBF',
  'HBG',
  'HBM',
  'HBU',
  'HBV',
  'HCG',
  'HD',
  'HDA',
  'HDB',
  'HDD',
  'HDE',
  'HDG',
  'HDH',
  'HDI',
  'HDK',
  'HDM',
  'HDP',
  'HDR',
  'HDS',
  'HDT',
  'HGD',
  'HJM',
  'HJU',
  'HKL',
  'HLS',
  'HMD',
  'HP',
  'HPS',
  'HRS',
  'HSD',
  'HSM',
  'HST',
  'HSU',
  'HSV',
  'HTR',
  'HTW',
  'HVC',
  'HVI',
  'JAM',
  'JAS',
  'JU',
  'JUA',
  'JUG',
  'JUM',
  'JUS',
  'LS',
  'MZ',
  'MZG',
  'PS',
  'PSA',
  'RS',
  'RSA',
  'SA',
  'SAB',
  'SAM',
  'SAS',
  'SMB',
  'SSF',
  'SSM',
  'ST',
  'STA',
  'STB',
  'STD',
  'STE',
  'STM',
  'STR',
  'SU',
  'SUA',
  'SUE',
  'SUM',
  'TDZ',
  'TEZ',
  'THD',
  'TSM',
  'TW',
  'TWA',
  'TWD',
  'TWM',
  'TWS',
  'VCA',
  'VDA',
  'VI',
  'VIA',
  'VIC',
  'VIE',
  'VIL',
  'VW',
  'ZDD',
  'ZED',
  'WAL',
  'MMR',
  'CH_ast',
  'ECO',
  'VEO',
  'HVB',
  'RL',
  'FZ',
  'HGV',
  'GVA',
  'FAK',
  'DZA',
  'CHI',
  'HCO',
  'WA_ast',
  'GOR',
  'GVI',
  'MW',
  'DCO',
  'GO_ast',
];
